.Widget {
	display: flex;
	align-items: center;
}

.song-cover {
	margin: 0;
	padding: 0;
	z-index: 999;
	height: 6.25rem;
	width: 6.25rem;
	text-align: center;
	object-fit: contain;
	border-radius: 8%;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.song-container {
	position: relative;
	display: flex;
	height: 3.4rem;
	max-width: 25rem;
	min-width: 12rem;
	flex-direction: column;
	justify-content: space-around;
	align-items: left;
	background-color: rgb(34, 34, 34);
	border-top-right-radius: 0.8rem;
	border-bottom-right-radius: 0.8rem;
	padding: 1rem;
	padding-left: 2rem;
	padding-right: 2rem;
	margin-left: -0.5rem;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	font-family: 'Ubuntu', sans-serif;
	transition: all 0.3s ease-in-out;
}

.title {
	margin: 0;
	padding: 0;
	font-size: 1.5rem;
	font-weight: 600;
	color: rgb(221, 221, 221);
	max-width: 28rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	z-index: 10;
	text-shadow: -3px 2px #0a0e27;
}

.artist {
	font-size: 0.8rem;
	margin: 0;
	padding: 0;
	font-weight: 500;
	color:rgb(216, 216, 216);
	position: relative;
	z-index: 10;
	text-shadow: -3px 2px #0a0e27;
}

.song-BG {
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	object-fit: cover;
	border-top-right-radius: 0.8rem;
	border-bottom-right-radius: 0.8rem;
	filter: blur(8px) opacity(60%) grayscale(50%);
}